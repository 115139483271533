<template>
    <div class="pa-2 rounded-pill scanned-product-bloc">
        <img :src="productImgUrl" class="product-img d-flex align-self-center" />

        <div class="product-infos px-2">
            <span class="font-weight-bold product-name primary--text">{{translateName(basketProduct.product.name)}}</span>
            <span class="font-weight-bold primary--text product-total-price">{{formatPrice(basketProduct.product.price * basketProduct.qty)}}</span>
            <span class="primary--text product-price">{{formatPrice(basketProduct.product.price)}}/{{$t("unit")}}</span>
        </div>

        <v-spacer></v-spacer>

        <div class="mr-5 product-qty">
            <span class="primary--text">{{basketProduct.qty}}</span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TicketProductBloc",
        props: ['basketProduct'],
        data() {
            return {}
        },
        computed: {
            productImgUrl() {
                if (this.basketProduct && this.basketProduct.product) {
                    return this.config.WS_URL + `/catalog/product/image/${this.basketProduct.product.image}` //URL A CHANGER
                }
                return this.config.WS_URL + `0`
            }
        },
        mounted() {
        }
    };
</script>

<style scoped>
.scanned-product-bloc{
    background: white;
    width: 90%;
    margin: 0 5%;
    display: flex;
    border: 1px solid var(--color-primary);
    margin-bottom: 5px;
}

.product-img{
    width: 84px;
    height: 84px;
    background-position: 50%;
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
}

.product-infos{
    display: flex;
    align-self: center;
    flex-direction: column;
    overflow: hidden;
    font-size: .8rem;
}

.product-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-total-price{
    font-size: 1.2rem;
}

.product-qty{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    font-size: 0.8rem;
}

</style>
