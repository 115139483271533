<template>
    <div v-show="showTicket" class="ticket">
        <div ref="header" class="d-flex flex-column align-center ticket-header">
            <Breadcrumbs :active="isNotNull(this.$route.query.shareQR) ? 2 : 3"></Breadcrumbs>
            <p v-if="$route.query.shareQR" class="text-center font-weight-bold text-body-1 amount accent--text" style="width: 90%; margin: 0 auto;">{{currentDate}}</p>
            <p v-if="$route.query.shareQR" class="text-center font-weight-bold text-body-1 amount primary--text" style="width: 90%; margin: 0 auto;">{{$t("ticketSharedBasket")}}</p>
            <p v-else-if="isRefund == true" class="text-center font-weight-bold text-body-1 amount primary--text" style="width: 90%; margin: 0 auto;">{{$t("ticketValidBasketRefund")}}</p>
            <p v-else class="text-center font-weight-bold text-body-1 amount primary--text" style="width: 90%; margin: 0 auto;">{{$t("ticketValidBasket")}}</p>
            <v-img class="my-7" :src="qrCodeImg" max-width="128" contain></v-img>
            <p class="text-center font-weight-bold text-h5 amount primary--text" style="width: 90%; margin: 0 auto;">{{formatPrice(lastTicket.totalAmount)}} / {{nbTotalProducts}} Art.</p>
            <p v-if="showDiscount" class="text-center font-italic text-caption amount primary--text" style="width: 90%; margin: 0 auto;">Coupon (PRW10) : - {{formatPrice(lastTicket.totalAmount * 0.1)}}</p>
    
            <div v-if="lastTicketComment && lastTicketComment.paymentReference && !lastTicketComment.reversed" class="mt-4 full-width d-flex align-center flex-column">
                <v-btn elevation="0" color="primary" outlined icon @click="reversePayment()" large>
                    <v-icon>mdi-credit-card-refund</v-icon>
                </v-btn>
                <span class="text-caption font-weight-medium primary--text text-center">{{$t("orderDetailsRefund")}}</span>
            </div>
        </div>
        <v-divider class="mx-7 my-4 primary"></v-divider>
        <div class="ticket-content" :style="{'max-height': `calc(calc(var(--vh, 1vh) * 100) - ${headerHeight}px - ${componentHeaderHeight + 200}px)`}">
            <TicketProductBloc v-for="basketProduct in lastTicket.basketProducts" :key="basketProduct.id" :basketProduct="basketProduct" />
        </div>

        <!-- <v-divider class="mx-7 my-4 primary"></v-divider> -->

        <!-- <TicketSendModal :enabled="activeReceiptBtn" :btnLabelKey="'sendReceipt'"/> -->

    </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs";
import TicketProductBloc from "@/components/TicketProductBloc";
import TicketSendModal from "@/components/TicketSendModal";
import {pageInfoMixin} from "@/mixins/pageInfoMixin";
const qrcodeGenerator = require('qrcode-generator')
const moment = require("moment")
import { changeBasketComment } from "@/services/BasketService"
var Utils = require("@/assets/js/Utils");

export default {
    name: "Ticket",
    mixins: [pageInfoMixin],
    components: { Breadcrumbs, TicketProductBloc, TicketSendModal},
    data () {
        return {
            showReceiveTicketModal: false,
            tabToShow: "email",
            email: null,
            tel: null,
            phoneNumber: "",
            footerHeight: 0,
            componentHeaderHeight: 0,
            currentDate: null,
            activeReceiptBtn: false,
            qrCodeImg: null,
            showTicket: false,
            showDiscount: false,
            isRefund: false
        }
    },
    mounted () {
        /*this.footerHeight = this.$refs.footer.offsetHeight
        this.componentHeaderHeight = this.$refs.header.offsetHeight;*/
        console.log(this.lastTicketComment);
    },
    async created(){
        this.showLoader({
            message: this.$t("ticketLoadingMessage"),
            color: this.$vuetify.theme.themes.light.primary
        })
        moment.locale(this.$i18n.locale)
        this.currentDate = moment().format("dddd DD MMMM, HH:mm")
        if(this.$route.query.shareQR){
            this.subscribeMqtt();
            if(this.nbrProductsInBasket > 0){
                await this.shareBasketInDatabase()
            }
        }else{
            if(this.nbrProductsInBasket > 0){
                await this.validBasketInDatabase({comment: JSON.stringify(this.basketComment)})
            }
        }
        this.qrCodeImg = this.getQrCodeImg()

        this.email = this.customerEmail
        let queryParams = this.getQueryParams()
        if(queryParams.data ){
            let parsedQueryParams = JSON.parse(queryParams.data)
            console.log('DLN data',parsedQueryParams);
            if(parsedQueryParams.result == "WPI_RESULT_SUCCESS"){
                this.isRefund = true
                let comment = {...this.lastTicketComment, ...{reversed: true}}
                await changeBasketComment(this.lastTicket.complexId, JSON.stringify(comment))
                this.updateLastTicketComment(JSON.stringify(comment))
                let query = Object.assign({}, this.$route.query);
                delete query.status;
                delete query.reference;
                this.$router.replace({ query });

                this.showSnackbar({
                    message: this.$t("reversedSuccessSnackBar"),
                    type: "success",
                    timeout: 10
                })
            }else if(parsedQueryParams.result == "WPI_RESULT_FAILURE"){
                this.showSnackbar({
                    message: this.$t("reversedErrorSnackBar"),
                    type: "error",
                    timeout: 10
                })
            }
        }
        this.hideLoader();
        this.showTicket = true
    },
    computed:{
        nbTotalProducts(){
            return this.lastTicket.basketProducts.reduce((acc, basketProduct) => basketProduct.qty + acc, 0)
        },
        lastTicketComment(){
            return JSON.parse(this.lastTicket.comment)
        }
    },
    methods:{
        async subscribeMqtt(){
            await this.$mqtt.initMqttClient()

            let customerPaymentWaitingTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_WAITING.format({complexId: this.lastTicket.complexId });
            this.$mqtt.subscribeMqtt(customerPaymentWaitingTopic, () => {
                this.$mqtt.listenMqttMessage(customerPaymentWaitingTopic, message => {
                    this.showLoader({
                        message: this.$t("waitingCustomerPayment"),
                        color: this.$vuetify.theme.themes.light.primary
                    })
                })
            })

            let customerPaymentTicketTopic = this.$mqtt.topics.DEFAULT_TOPIC + this.$mqtt.topics.PAYMENT_TICKET.format({complexId: this.lastTicket.complexId });
            this.$mqtt.subscribeMqtt(customerPaymentTicketTopic, () => {
                this.$mqtt.listenMqttMessage(customerPaymentTicketTopic, async message => {
                    this.hideLoader();
                    this.activeReceiptBtn = true
                    this.clearBasket()
                    await this.updateLastTicketWithDatabaseBasket(this.lastTicket.complexId)
                    if(this.lastTicketComment && this.lastTicketComment.coupon == "PRW10"){
                        this.showDiscount = true
                    }
                    this.showSnackbar({
                        message: this.$t('orderPaidByClient'),
                        type: "success",
                        timeout: 10
                    })
                    this.$mqtt.unsubscribe(customerPaymentWaitingTopic, () => {});
                    this.$mqtt.unsubscribe(customerPaymentTicketTopic, () => {});
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 3000);
                })
            })
        },
        getQrCodeImg(){
            let data = window.location.origin + "/payment/customer?id=" + this.lastTicket.complexId

            let settings = localStorage.getItem("AugmentedSeller_settings")
            if(settings){
                let customerPayment = JSON.parse(settings)

                if(customerPayment.coupons)
                    data += "&c=1"

                if(customerPayment.crossSell)
                    data += "&cs=1"

                if(customerPayment.firstName)
                    data += "&fn=1"

                if(customerPayment.lastName)
                    data += "&ln=1"

                if(customerPayment.phoneNumber)
                    data += "&pn=1"

                if(customerPayment.email)
                    data += "&e=1"

                if(customerPayment.selectedPaymentMethods)
                    data += "&pm=" + customerPayment.selectedPaymentMethods.join(",")

                if(customerPayment.shipping)
                    data += "&s=1"
            }

            let qr = qrcodeGenerator(0, 'L');
            qr.addData(data);
            qr.make();
            return qr.createDataURL(10, 0);
        },
        reversePayment(){
            this.startTapOnMobile()
        },
        startTapOnMobile(){
            this.showLoader({
                message: this.$t("scanTerminalLoaderText"),
                color: this.$vuetify.theme.themes.light.primary
            })
            let returnUrl = window.location.origin + '/payment/ticket'
            var formatedParams = Utils.formatURI(`WPI_SERVICE_TYPE=WPI_SVC_CANCEL_PAYMENT&WPI_REQUEST={"paymentSolutionReference":${this.lastTicketComment.paymentReference}}&WPI_SERVICE_VERSION=2.1&WPI_SERVICE_SENDER=${returnUrl}&key=12FB4ACF031`)
            const finalUrl = `wltaponmobile://wpi.worldline?${formatedParams}`
            console.log('DLN formated params ticket refund',formatedParams);
            console.log('DLN final url ticket',finalUrl);
            window.location.replace(finalUrl)
        },
        getQueryParams(){
            const params = {};
            const queryString = window.location.search.substring(1);
            console.log('DLN url ticket',window.location.search);
            const pairs = queryString.split("&");
            for (const pair of pairs) {
                const [key, value] = pair.split("=");
                params[decodeURIComponent(key)] = decodeURIComponent(value);
            }
            return params;
        }
    }
}
</script>

<style scoped>
.amount{
    font-size: 1.5em;
}

.receive-ticket-button{
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 0.7rem;
}

.pub{
    width: 70%;
    display: block;
    margin: 0 auto;
}

.ticket-footer{
    background-color: white;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.receive-ticket-modal-action{
    border-radius: 8px;
}
.ticket-content{
    overflow-y: scroll;
}
</style>

<style>
.v-tabs{
    width: auto !important;
    display: block;
    margin: 0 auto;
    flex: none !important;
}

.ticket-modal{
    overflow-y: unset;
}
</style>

